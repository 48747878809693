import React, {useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {Button, Checkbox, Input, message, Modal, Tooltip} from 'antd';
import {NavLink} from 'react-router-dom';
import {tap} from "rxjs/operators";
import {ServicesModel} from '../../common';
import css from './index.module.less';
import classNames from 'classnames';
import {PRIVACY_PACTION, REGISTER_PACTION} from "../../helps/helpsPaction";

export const RegPage = (props) => {
    const {currentModal} = props;
    const { handleSubmit, control, watch, getValues /*,setValue*/ } = useForm(); // initialise the hook
    const isremember = watch('remember', []);
    const mobile = watch('mobile', '');
    const [mobileError,setMobileError] = useState(false);
    const [count, setCount] = useState(60);
    const [countdown, showCountdown] = useState(false);
    const [loginType, setLoginType] = useState(0);
    useEffect(()=>{
        const {loginType=2} = props.location.state||{};
        setLoginType(loginType);
    }, [props.location.state]);
    const onSubmit = data => {
        let _data  = data ||{};
        const { password,repassword } = data;
        if(!password || (password && password!==repassword)){
            return message.error({content:"密码不正确！",style:{ marginTop: '20vh',}})
        };
        if (_data.remember.length>0) {
            _data.remember=1;
        };
        _data.remember = void 0;
        ServicesModel.doRegister({type:loginType,..._data}).pipe(
            tap(res=>console.log(res))
            ).subscribe({
                next:res=>{
                    Modal.warning({
                        width:'350px',
                        title:'温馨提示',
                        content: '恭喜您注册成功，是否现在登录？',
                        okText:'去登录',
                        cancelText:'取消',
                        maskClosable: true,
                        onOk: ()=>{
                            window.location.href = '/login'
                        },
                        onCancel:()=>{

                        }
                    });
                },
                error:err=>{},
                complete:()=>{}
            });
    };
    const getVerifyCode = () => {
        const { mobile } = getValues();
        if (!mobile) {
            return message.error({content:'请填写正确的手机号码！',style:{ marginTop: '20vh',}});
        };
        startCountdown();

        ServicesModel.getSendCode({mobile,event:loginType === 1 ? 'register' :'orregister'}).pipe(
            tap(res=>{
                console.log(res)
            })
        ).subscribe({
            next(res){
                message.success({content:'发送成功',style:{ marginTop: '20vh',}})
                // console.log(res)
            }
        })
    }
    let IntervalId = '';
    const startCountdown = () => {
        showCountdown(true);
        let _count = count;
        IntervalId = setInterval(() => {
                if (_count > 0) {
                    _count--;
                    setCount(_count);
                } else {
                    showCountdown(false);
                    clearInterval(IntervalId);
                    setCount(60);
                }
            }
            , 1000);

    };

    const [serviceVisible,setServiceVisible] = useState(false);
    useEffect(()=>{
        if(mobile.length === 11){
            ServicesModel.verifyMobile({type:loginType,mobile}).pipe(
                tap(res=>{
                })
            ).subscribe({
                next({data}){
                    if(data){
                        setMobileError(true);
                        message.error({content:'手机号码已存在',style:{ marginTop: '20vh',}});
                    }else{
                        setMobileError(false);
                    }
                    // console.log(res)
                }
            })
        }else{
            setMobileError(false);
        }
    },[mobile]);


    const [modalContent,setModalContent] = useState();

     return (
         <div className={ classNames(css.reg,css.iForm,currentModal ==='reg'? css.show : css.hide) }>
             <div className={ css.regWrap }>
                 <form onSubmit={handleSubmit(onSubmit)} className={ css.formWrap }>
                     <div className={css.loginBox}>
                         <div>已有平台账号？</div>
                         <NavLink to="/login" style={{color: '#009F8F'}}>去登录</NavLink>
                     </div>
                     <div className={ css.formItem }>
                         <div className={ classNames(css.tapsWrap)  }>
                             <div className={ classNames(css.tap,loginType===2?css.active:'') }
                                  onClick={ ()=>setLoginType(2) }>个人注册</div>
                             <div className={ classNames(css.tap,loginType===1?css.active:'') }
                                  onClick={ ()=>setLoginType(1) }>企业注册</div>
                         </div>
                     </div>
                     {
                         loginType === 1 && <div className={ css.formItem }>
                             <div className={ css.formItemLabel }>
                                 登录账号
                             </div>
                             <div className={ css.formItemCon }>
                                 <Controller
                                     as={<Input style={{width:'100%'}} autoComplete="off"/>}
                                     name="nickname"
                                     control={control}
                                     placeholder="请输入登录账号"
                                     defaultValue="" />
                             </div>
                         </div>
                     }

                     <div className={ css.formItem }>
                         <div className={ css.formItemLabel }>
                             手机号码
                         </div>
                         <div className={ css.formItemCon }>
                             <Controller
                                 as={<Input maxLength={11} style={{width:'100%',height:'32px'}} autoComplete="off"/>}
                                 name="mobile"
                                 control={control}
                                 placeholder="请输入手机号码"
                                 defaultValue="" />
                         </div>

                     </div>
                     <div className={ css.formItem } >
                         <div className={ css.formItemLabel }>
                             验证码
                         </div>
                         <div className={ css.formItemCon }>
                             <Controller
                                 as={<Input placeholder='请输入验证码' autoComplete="off" suffix={
                                     <div >
                                         { !countdown ?
                                             <div  className={css.codeBtn} onClick={() => {
                                                 getVerifyCode();
                                             }}>获取验证码</div>
                                             :
                                             <div className={css.codeBtn} style={{color: "#eaeaea"}}>{count}s后获取</div>}
                                     </div>
                                 } />}
                                 name="code"
                                 control={control}
                             />
                         </div>
                     </div>
                     <Tooltip title={<span>长度为6~20个字符,<br/>必须包含字母/数字,<br/>不允许有空格、中文</span>}  trigger={['focus','hover']} >
                         <div className={ css.formItem }>
                             <div className={ css.formItemLabel }>
                                 设置密码
                             </div>
                             <div className={ css.formItemCon }>
                                 <Controller
                                     as={<Input.Password style={{width:'100%'}} />}
                                     name="password"
                                     control={control}
                                     placeholder="请输入密码"
                                     defaultValue="" />
                             </div>
                         </div>
                     </Tooltip>
                     <div className={ css.formItem }>
                         <div className={ css.formItemLabel }>
                             确认密码
                         </div>
                         <div className={ css.formItemCon }>
                             <Controller
                                 as={<Input.Password style={{width:'100%'}} />}
                                 name="repassword"
                                 control={control}
                                 placeholder="请确认密码"
                                 defaultValue="" />
                         </div>
                     </div>
                     <div className={ css.formItem } >
                         <div style={{justifyContent:'center',width: '245px'}}>
                             <Controller
                                 as={
                                     <Checkbox.Group style={{fontSize: '10px'}}>
                                         <Checkbox value="remember" />
                                         &nbsp;&nbsp;我已阅读并同意 <Button style={{padding: '0',height:'20px',lineHeight:'20px',fontSize: '10px',color: '#369B81'}} type={"link"} onClick={()=>{setModalContent(REGISTER_PACTION);setServiceVisible(true)}}>《会员注册服务协议》</Button>和<Button onClick={()=>{setModalContent(PRIVACY_PACTION);setServiceVisible(true)}} style={{padding: '0',height:'20px',fontSize:'10px',lineHeight:'20px',color: '#369B81'}} type={"link"}>《隐私保护声明》</Button>

                                     </Checkbox.Group>
                                 }
                                 name="remember"
                                 control={control}
                             />
                         </div>
                     </div>
                     <div className={ css.formItem }>
                         <div className={ css.formItemCon }>
                             <div className={ css.btnWrap }>
                                 <div className={ css.btnInner }>
                                     <Button size="large"
                                             disabled={!isremember.length>0 || mobileError}
                                             title={ !isremember.length>0?'请先勾选同意按钮':'' }
                                             type="primary" htmlType="submit">立即注册</Button>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </form>
             </div>
             <Modal visible={serviceVisible} closable={false} footer={null} width={'650px'}>
                 <div  style={{height: '600px',overflow:'auto',marginTop: '20px'}}>
                     <div dangerouslySetInnerHTML={{__html: modalContent}} style={{marginBottom: '15px'}}/>
                     <div className={css.btnWrap}>
                         <div className={ css.btnInner }>
                            <Button type={"primary"} style={{width:'150px', flex: "none", margin: "auto"}} onClick={() => setServiceVisible(false)} >我知道了</Button>
                         </div>
                     </div>
                 </div>
             </Modal>
         </div>


    )
}
