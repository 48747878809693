import React, {useEffect, useState} from 'react';
import {Avatar, message, Modal, Upload} from 'antd';
import {useUserInfo} from '../../../hook';
import css from './UserInfo.module.less';
import avatar from 'aliasimgurl/default_avatar.png';
import authenticate from 'aliasimgurl/icon_authenticate.png';
import authenticated from 'aliasimgurl/icon_authenticated.png';
import moment from "moment";
import {ServicesModel, UploadConfig} from "../../../common";
import {aesEncrypt, store} from "../../../helps";
import { useHistory} from 'react-router-dom'

export const UserInfo  = ()=>{

    const [data,setData] = useUserInfo();
    const action = 'https://upload-z0.qiniup.com';
    const [params,setParams] = useState({});
    const beforeUpload = (file)=> {
        return new Promise((resolve, reject)=>{
            if(file.size > 1024*1024*10){
                message.error('上传图片为' + (file.size/1024/1024).toFixed(2)+'M ，请控制在10M以内！');
                reject();
            } else if( !accept.includes(file.name.slice(file.name.lastIndexOf('.')))  ){
                message.error('图片格式不正确');
                reject();
            }
            UploadConfig.fetchUploadToken().then(({data:{data:{uptoken:token}}})=>{
                // this.setState({
                //     uptoken: token,
                //     filename: file.name
                // });
                setParams({uptoken: token,filename: file.name})
                resolve();
            });

        })
    };
    useEffect(()=>{
        console.log("data=======",data)
    },[data]);
    const accept='.jpg, .jpeg, .png, .gif';
    const handleChange = ({ file,fileList }) =>{
        if(file.status === 'done' || file.status === "removed"){
            ServicesModel.updateAvatar({type:3,avatar:file.response.key}).subscribe({
                next:res=>{
                    // let _t = setTimeout(()=>getData(),5000);
                    // setTimer(_t);
                    ServicesModel.getUserInfo().subscribe({
                        next:({data,code})=>{
                            if (data && data.userinfo) {
                                setData(data.userinfo);
                            }
                        }
                    });
                }
            });
        }
        if(file.status === 'error'){
            message.error("图片上传失败,请重新上传！");
        }
        // this.setState({ fileList })
    };

    return (
        <div className={ css.userinfo }>
            <h3>个人中心</h3>
            <Upload action={action} accept={accept} beforeUpload={beforeUpload}
                    onChange={handleChange}
                    showUploadList={false}
                    data={{
                        token: params.uptoken,
                        key: moment(new Date()).format('YYYY-MM-DD')+'/'+parseInt(9999*Math.random())+'/' +params.filename
                    }}
            >
                <div className={css.avatar}>
                    <Avatar src={data.avatar|| avatar}  size={ 56 } />
                    <div className={css.updateAvatar}>
                        修改头像
                    </div>
                </div>
            </Upload>
            <div className={ css.phone }>{data.mobile}</div>
            {data.type === 1  && <div className={ css.company }>{data.store_name || data.username}</div>}
            {data.apply_state == 2 ?  <div className={css.authenticate}>
                <img src={authenticated} alt=""/>
            </div>:<a className={css.authenticate} href='/account/setting/userauth' >
                <img src={authenticate} alt=""/>
                <span>立即认证</span>
            </a>
            }
            {/*<NavLink className={ css.btn } to="/account/setting/userauth" >马上认证</NavLink>*/}
        </div>

    )
}
