import React from 'react';
import css from './index.module.less';
import {TitleBox} from "../components/titleBox";
import aboutFlow from "aliasimgurl/about_flow.png";
import aboutFlow01 from "aliasimgurl/icon_flow_01.png";
import aboutFlow02 from "aliasimgurl/icon_flow_02.png";
import aboutFlow03 from "aliasimgurl/icon_flow_03.png";
import aboutFlow04 from "aliasimgurl/icon_flow_04.png";

/** 版权所有 */
export const Copyright = (props) => {
    return (
        <div className={css.body}>
            <TitleBox title={'版权所有'} />
            <div className={css.content}>
                <p>1.用户发表并将其上传到本网站的任何内容，本平台享有永久的、不可撤销的、免费的、非独家的使用权和转授权的权利，包括但不限于修改、复制、发行、展览、改编、汇编、出版、翻译、信息网络传播、广播、表演和再创作及著作权法等法律法规确定的其他权利。</p>
                <p>2.本平台特有的标识、版面设计、编排方式等版权均属本平台享有，未经本平台许可，不得任意复制或转载。</p>
                <p>3.用户从本平台的服务中获得的信息，未经本平台许可，不得任意复制或转载。</p>
                <p>4.使用本平台的任何内容均应注明"来源于昆明农村产权流转服务平台(www.china-forest.com)"及署上作者姓名，按法律规定需要支付稿酬的，应当通知本平台及作者及支付稿酬，并独立承担一切法律责任。</p>
                <p>5.本平台享有所有作品用于其它用途的使用权和优先权，包括但不限于网站、电子杂志、平面出版等，作者在本平台发布内容时视为同意本条款。</p>
                <p>6.本平台所有内容仅代表作者自己的立场和观点，与本平台无关，由作者本人承担一切法律责任。</p>
                <p>7.恶意转载本平台内容的，本平台保留将其诉诸法律的权利。</p>
            </div>
        </div>
    )
}