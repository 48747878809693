import React, {Fragment, useEffect, useState} from 'react';
import {UploadImage} from '../../../components';
import {Button, Modal} from 'antd';
import {Card} from '../components';
import {UserAuthForm} from './UserAuthForm';
import {UserAuthSteps} from './UserAuthSteps';
import iconReject from 'aliasimgurl/icon_reject.png';
import authSuccess from 'aliasimgurl/icon_auth_success.png';
import css from './userauth.module.less';
import {useDataApi} from "../../../hook";
import {useHistory} from 'react-router-dom'
import {ServicesModel} from "../../../common";
import {store} from "../../../helps";

export const UserAuth = ()=>{
    const [step,setStep] = useState(0);
    const [{data, isLoading,setData},updateParams] = useDataApi({url: '/api/getAuthentication', method: 'post'});
    const {push} = useHistory();

    const onSubmit = (v)=>{
        updateParams({url: '/api/getAuthentication', method: 'post'});
        setStep(1);
    };
    useEffect(()=>{
        const {apply_state=''} = data;
        let _data = data ;
        _data.site = _data.county_id ? [data.province_id,data.city_id,data.county_id]:[]
        setData({..._data});
        switch (apply_state){
            case "1":
                setStep(1);
                break;
            case "2":
                setStep(2);
                break;
            case "3":
                setStep(2);
                break;
            default:;
        }
    },[isLoading]);
    return (
        <div className={ css.formBody }>
            {!isLoading && <Card title="用户认证">
                <UserAuthSteps currentStep={ step } setStep={ setStep }>
                        <div className={ css.editForm }>
                            {!isLoading && <UserAuthForm onSubmit={ onSubmit } data={data}/>}
                        </div>
                        <div>
                            <div className={ css.verifyInfo }>
                                <div className={ css.item }>
                                    <div className={ css.label }>真实姓名：</div>
                                    <div className={ css.content }>{data.legal_person}</div>
                                </div>
                                <div className={ css.item }>
                                    <div className={ css.label }>身份证号：</div>
                                    <div className={ css.content }>{data.legal_identity}</div>
                                </div>
                                <div className={ css.item }>
                                    <div className={ css.label }>行业分类：</div>
                                    <div className={ css.content }>{data.business_type}</div>
                                </div>
                                {data.apply_type == 1 &&
                                <Fragment>
                                    <div className={ css.item }>
                                        <div className={ css.label }>公司名称：</div>
                                        <div className={ css.content }>{data.company_name}</div>
                                    </div>
                                    <div className={ css.item }>
                                        <div className={ css.label }>注册资金：</div>
                                        <div className={ css.content }>{data.reg_capital} (万元)</div>
                                    </div>
                                    <div className={ css.item }>
                                        <div className={ css.label }>所属地区：</div>
                                        <div className={ css.content }>{data.address}</div>
                                    </div>
                                </Fragment>}

                                <div className={ css.item }>
                                    <div className={ css.label }>邮箱：</div>
                                    <div className={ css.content }>{data.email}</div>
                                </div>
                                {data.apply_type == 1 ?  <div className={ css.item }>
                                    <div className={ css.label }>企业工商营业执照：</div>
                                    <div className={ css.content }>
                                        {data.business_licence_cert && <UploadImage disabled={true} uploadNum={1}
                                            value={data.business_licence_cert}/>}
                                    </div>
                                </div>: <Fragment>
                                    <div className={ css.item }>
                                        <div className={ css.label }>身份证正反面照片：</div>
                                        <div className={ css.content }>
                                            {data.legal_identity_cert && <UploadImage disabled={true} uploadNum={2} value={data.legal_identity_cert}/>}
                                        </div>
                                    </div>
                                    <div className={ css.item }>
                                        <div className={ css.label }>手持身份证照片：</div>
                                        <div className={ css.content }>
                                            { data.legal_identity_cert_un &&  <UploadImage disabled={true} uploadNum={1} value={data.legal_identity_cert_un}/>
                                            }
                                        </div>
                                    </div>
                                </Fragment>
                                }
                            </div>
                            <div className={ css.tips }>您的资料提交成功，进入平台审核阶段，请耐心等待！</div>
                            <div className={ css.help }>如有其他任何疑问，请致电0871-65819655！</div>

                        </div>
                        <div className={ css.authRes }>
                            {
                                data.apply_state == 2 && <Fragment>
                                    <div className={ css.verifyInfo }>
                                        <div className={ css.item }>
                                            <div className={ css.label }>真实姓名：</div>
                                            <div className={ css.content }>{data.legal_person}</div>
                                        </div>
                                        <div className={ css.item }>
                                            <div className={ css.label }>身份证号：</div>
                                            <div className={ css.content }>{data.legal_identity}</div>
                                        </div>
                                        <div className={ css.item }>
                                            <div className={ css.label }>行业分类：</div>
                                            <div className={ css.content }>{data.business_type}</div>
                                        </div>
                                        {data.apply_type == 1 &&
                                        <Fragment>
                                            <div className={ css.item }>
                                                <div className={ css.label }>公司名称：</div>
                                                <div className={ css.content }>{data.company_name}</div>
                                            </div>
                                            <div className={ css.item }>
                                                <div className={ css.label }>注册资金：</div>
                                                <div className={ css.content }>{data.reg_capital} (万元)</div>
                                            </div>
                                            <div className={ css.item }>
                                                <div className={ css.label }>所属地区：</div>
                                                <div className={ css.content }>{data.address}</div>
                                            </div>
                                        </Fragment>}

                                        <div className={ css.item }>
                                            <div className={ css.label }>邮箱：</div>
                                            <div className={ css.content }>{data.email}</div>
                                        </div>
                                        {data.apply_type == 1 ?  <div className={ css.item }>
                                            <div className={ css.label }>企业工商营业执照：</div>
                                            <div className={ css.content }>
                                                {data.business_licence_cert && <UploadImage disabled={true} uploadNum={1}
                                                                                            value={data.business_licence_cert}/>}
                                            </div>
                                        </div>: <Fragment>
                                            <div className={ css.item }>
                                                <div className={ css.label }>身份证正反面照片：</div>
                                                <div className={ css.content }>
                                                    {data.legal_identity_cert && <UploadImage disabled={true} uploadNum={2} value={data.legal_identity_cert}/>}
                                                </div>
                                            </div>
                                            <div className={ css.item }>
                                                <div className={ css.label }>手持身份证照片：</div>
                                                <div className={ css.content }>
                                                    { data.legal_identity_cert_un &&  <UploadImage disabled={true} uploadNum={1} value={data.legal_identity_cert_un}/>
                                                    }
                                                </div>
                                            </div>
                                        </Fragment>
                                        }
                                    </div>
                                    <div className={ css.success }>
                                        <img src={authSuccess} alt=""/>
                                        <span>您的资质认证已审核通过成功！</span></div>
                                    <div className={ css.help }>如有其他任何疑问，请致电0871-65819655！</div>
                                </Fragment>
                            }
                            {
                                data.apply_state == 3 && <Fragment>
                                    <div className={ css.reject }><img src={iconReject} alt=""/> <span>抱歉,您提交的资料信息审核不通过，请重新填写提交！</span></div>
                                    <div className={ css.item }>
                                        <div className={ css.label }>驳回原因：</div>
                                        <div className={ css.content }>{data.review_msg}</div>
                                    </div>
                                    <div className={ css.help }>如有其他任何疑问，请致电0871-65819655！</div>
                                    <div className={ css.toDoBtn }>
                                        <Button onClick={()=>setStep(0) } type={"primary"}>重新填写</Button>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    </UserAuthSteps>
            </Card> }
        </div>
    );
}