import React, {useState,} from 'react';
import {Button, Form, Input, message,Tooltip} from 'antd';
import {NavLink} from 'react-router-dom';
import {tap} from "rxjs/operators";
import {ServicesModel} from '../../common';
import {ImgVerifyCode} from '../../components';
import classNames from 'classnames';
import css from './index.module.less';

export const RecoverPage = (props) => {
    const {currentModal} = props;

    const [currentTap,setCurrentTap] = useState(2);
    const [form] = Form.useForm();
    const [count, setCount] = useState(60);
    const [countdown, showCountdown] = useState(false);

    const onSubmit = data => {
        // apply_type:	1企业 2个人 3专家
        ServicesModel.doRetrievepwd({type:'mobile',apply_type:currentTap,...data}).pipe(
            tap(res=>console.log(res))
        ).subscribe({
            next:(res)=>{
                message.success("修改成功",2, ()=>window.location.href = '/login')
            }
        });
    };
    /** 获取手机验证码 */
    const getCode = () => {
        const mobile = form.getFieldValue('mobile');
        if(!mobile){
            return message.error({content: "手机号码不能为空",style:{ marginTop: '20vh',}});
        }else if( mobile.length !==11){
            return message.error({content:"手机号码不正确",style:{ marginTop: '20vh',}});
        }

        startCountdown();
        ServicesModel.getSendCode({mobile, event: currentTap === 1? 'retrievepwd':'orretrievepwd'}).pipe(
            tap(res => console.log(res))
        ).subscribe({
            success(res) {
                message.success({content:"验证码已发送，请注意查收！",style:{ marginTop: '20vh'}})
            }
        });
    };
    let IntervalId = '';
    const startCountdown = () => {
        showCountdown(true);
        let _count = count;
        setCount(60);
        IntervalId = setInterval(() => {
                if (_count > 0) {
                    _count--;
                    setCount(_count);
                } else {
                    showCountdown(false);
                    clearInterval(IntervalId);
                }
            }
            , 1000);

    };
    /**  个人 企业 状态切换 */
    const handleTapChange = (V)=>{
        setCurrentTap(V);
        showCountdown(false);
    }

    return (
        <div className={ classNames(css.recover,css.iForm,currentModal ==='recover'? css.show : css.hide) }>
            <div className={ css.recoverWrap }>
                <Form onFinish={onSubmit} form={form} validateTrigger={'onBlur'}  className={ css.formWrap }>
                    <div className={css.loginBox}>
                        <div>已有平台账号？</div>
                        <NavLink to="/login" style={{color: '#009F8F'}}>去登录</NavLink>
                    </div>
                    <div className={ css.formItem }>
                        <div className={ classNames(css.tapsWrap)  }>
                            <div className={ classNames(css.tap,currentTap===2?css.active:'') }
                                 onClick={ ()=>handleTapChange(2)}>个人找回</div>
                            <div className={ classNames(css.tap,currentTap===1?css.active:'') }
                                 onClick={ ()=>handleTapChange(1)}>企业找回</div>
                        </div>
                    </div>
                    <Form.Item label={'手机号码'}   name={'mobile'}  colon={false} labelAlign={"left"}>
                        <div className={css.right}>
                            <Input  placeholder="请输入手机号码" style={{width:'100%',height: '32px'}} autoComplete="off" />

                        </div>
                    </Form.Item>
                    <Form.Item  label={'验证码'} name="captcha" colon={false} labelAlign={"left"}>
                        <div className={css.right}>
                            <Input   placeholder="请输入图形验证码" autoComplete="off" suffix={ <div>
                                <ImgVerifyCode className={ css.imgcode } />
                            </div>
                            }/>
                        </div>
                    </Form.Item>
                    <Form.Item label="手机验证码"  name={'code'}  colon={false} labelAlign={"left"}>
                        <div className={css.right}>
                            <Input   placeholder='请输入手机验证码' autoComplete="off" suffix={
                                <div >
                                { !countdown ?
                                    <div  className={css.codeBtn} onClick={() => {
                                    getCode();
                                 }}>获取验证码</div>
                                    :
                                    <div className={css.codeBtn} style={{color: "#eaeaea"}}>{count}s后获取</div>}
                            </div>
                            } />
                        </div>
                    </Form.Item>

                        <Form.Item  label={'设置新密码'}  name="newpassword"  colon={false} labelAlign={"left"}>
                            <div className={css.right}>
                                <Tooltip title={<span>长度为6~20个字符,<br/>必须包含字母/数字,<br/>不允许有空格、中文</span>}  trigger={['focus','hover']} >
                                    <Input.Password  placeholder="请输入设置新密码" />
                                </Tooltip>
                            </div>
                        </Form.Item>

                    <div className={ css.formItem } style={{marginTop:'25px'}}>
                        <div className={ css.formItemCon }>
                            <div className={ css.btnWrap }>
                                <div className={ css.btnInner }>
                                    <Button size="large"
                                            type="primary"
                                            htmlType="submit">重置密码</Button>
                                </div>
                            </div>
                        </div>
                    </div>

                </Form>
            </div>
        </div>

    )
}
